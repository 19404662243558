import React from 'react';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

import { ListItem } from '../../CustomStyles/Typography';

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#4B5B69',
		fontFamily: 'Roboto',
		fontSize: '1.25rem',
		paddingTop: '1em',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.125rem',
		},
	},
	bulletCopy: {
		[theme.breakpoints.down('sm')]: {
			padding: '.5rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.125rem',
		},
	},
	linkText: {
		color: theme.mediumDarkGray,
	},
}));

export const FeatureBodyCopy = ({ _rawBodyCopy, routing, qbLogo, qbLink }) => {
	const classes = useStyles();
	return (
		<>
			<Grid
				item
				container
				direction='column'
				alignItems='flex-start'
				justifyContent='space-evenly'>
				{_rawBodyCopy.map((content, index) => (
					<PortableText
						key={index}
						content={content}
						// className={classes.text}
						serializers={{
							h2: ({ children }) => (
								<Typography
									variant='h2'
									style={{
										lineHeight: 0.9,
										color: routing && 'white',
									}}>
									{children}
								</Typography>
							),
							normal: ({ children }) => (
								<Typography
									variant='body1'
									role="heading"
									className={classes.text}
									style={{
										lineHeight: routing && '32px',
										color: routing && 'white',
									}}>
									{children}
								</Typography>
							),
							li: ({ children }) => (
								<Grid item>
									<ListItem>
										<li className={classes.bulletCopy} role="heading">{children}</li>
									</ListItem>
								</Grid>
							),
							internalLink: ({ children, internalSlug }) => (
								<Link to={`/${internalSlug}`} className={classes.linkText}>
									{children}
								</Link>
							),
							h4: ({ children }) => (
								<Typography
									variant='body1'
									className={classes.text}
									style={{
										fontSize: '1rem',
										marginLeft: '1rem',
									}}>
									{children}
								</Typography>
							),
							code: ({ children }) => (
								<Typography
									variant='body1'
									className={classes.text}
									style={{
										fontSize: '.8rem',
										marginLeft: '1rem',
									}}>
									{children}
								</Typography>
							),
							sup: ({ children }) => (
								<sup>
									{children}
								</sup>
							),
						}}
					/>
				))}
			</Grid>
			{qbLink ? (
				<Grid container>
					<Grid item xs={12}>
						<a href={qbLink} target='_blank' aria-label="Opens in a new tab">
							<GatsbyImage image={qbLogo?.node.appLogo.asset.gatsbyImageData} />
						</a>
					</Grid>
				</Grid>
			) : null}
		</>
	);
};
