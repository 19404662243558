import React from 'react';

import { Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { FeatureBodyCopy } from '../../General/Feature/FeatureBodyCopy';
import { FeatureButton } from '../../General/Feature/FeatureButton';
import { FeatureHeader } from '../../General/Feature/FeatureHeader';
import { FeatureContent } from '../../General/Feature/FeatureContent';

const BodyFeature = ({
	feature,
	accentColor,
	qbLogo,
	index,
	print,
	thumbnailVideoUrl,
}) => {
	const {
		bodyHeader,
		bodySubHeader,
		image,
		videoVariant,
		iosLink,
		qbLink,
		ctaText,
		_rawBodyCopy,
		ctaLink,
		internalLink,
	} = feature;

	const lg = useMediaQuery('(max-width: 1280px)');
	const xs = useMediaQuery('(max-width: 481px)');

	return (
		<Grid
			style={{ padding: lg ? '2rem 0 ' : '4rem 0' }}
			container
			spacing={4}
			justifyContent='space-between'
			alignItems='center'
			direction={index % 2 === 0 ? 'row-reverse' : 'row'}>
			<FeatureContent
				image={image}
				thumbnailVideoUrl={thumbnailVideoUrl}
				videoVariant={videoVariant}
			/>
			<Grid
				container
				item
				xs={12}
				md={6}
				direction='column'
				justifyContent='flex-start'
				alignItems='flex-start'>
				{!xs && (
					<FeatureHeader
						print={print}
						bodyHeader={bodyHeader}
						bodySubHeader={bodySubHeader}
					/>
				)}
				<FeatureBodyCopy
					_rawBodyCopy={_rawBodyCopy}
					qbLink={qbLink}
					qbLogo={qbLogo}
				/>
				<FeatureButton
					ctaLink={ctaLink}
					internalLink={internalLink}
					iosLink={iosLink}
					qbLink={qbLink}
					ctaText={ctaText}
					accentColor={accentColor}
					role="button"
          tabIndex={0}
				/>
			</Grid>
		</Grid>
	);
};

export default BodyFeature;
